/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.0
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ConsentStatus =
  | 'REQUESTED'
  | 'DECLINED'
  | 'GRANTED'
  | 'STEPUP'
  | 'APPROVED'
  | 'DENIED'
  | 'TOKENIZED'
  | 'REVOKED';

export const ConsentStatus = {
  Requested: 'REQUESTED' as ConsentStatus,
  Declined: 'DECLINED' as ConsentStatus,
  Granted: 'GRANTED' as ConsentStatus,
  Stepup: 'STEPUP' as ConsentStatus,
  Approved: 'APPROVED' as ConsentStatus,
  Denied: 'DENIED' as ConsentStatus,
  Tokenized: 'TOKENIZED' as ConsentStatus,
  Revoked: 'REVOKED' as ConsentStatus,
};
